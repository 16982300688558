import * as network from './network';

const getAllOrders = (params) => {
  return network.get('order', params);
};

const getOrderById = (id, params) => {
  return network.get('order/' + id, params);
};

const getOrdersOnSearch = (params) => {
  return network.get(`order`, params);
};

const getOrderItemAnswers = (id, params) => {
  return network.get('sub-cat-answer/answers/' + id, params);
};

const changeProductsQuantities = (payload) => {
  return network.post(`order/order-modify`, payload);
};

const setInstructionForProductLabel = (orderItemId, payload) => {
  return network.post(`order/instruction-update/${orderItemId}`, payload);
};

const setStatusOfProductApproval = (orderItemId, payload) => {
  return network.post(`order/order-item-status/${orderItemId}`, payload);
};

const getPastOrders = (payload) => {
  return network.get('order/order-history', payload, true);
};

const removeNotification = (payload) => {
  return network.post('order/notification', payload);
};

const putOrderStatus = (id, payload) => {
  const res = network.put('order/status/' + id, payload);
  return res;
};

const updateOrder = (id, payload) => {
  return network.put('order/' + id, payload);
};

const updateDeliveryAddress = (id, payload) => {
  return network.put(`order/update-address/${id}`, payload);
};

const getVariants = (id, params) => {
  return network.get(`stock/${id}`, params);
};

const updateVariantInOrder = (payload) => {
  return network.post(`order/updateOrderItems`, payload);
};

// Communication

const getMessages = (orderId) => {
  return network.get(`communication/${orderId}`);
};

const postMessage = (payload) => {
  return network.post(`communication/add`, payload);
};

const sendTextMessage = (payload) => {
  return network.post(`communication/sendsms`, payload);
};

const getDeliveryLabel = (id) => {
  return network.get(`order/deliveryLabel/${id}`, {}, true, 'application/pdf');
};

const getSubcatPhotos = (params) => {
  return network.get(`sub-cat-file-ans/user/${params?.user_id}`, params, true);
};

const getCountForPharmacyRejectedOrders = (params) => {
  return network.get('order/pharmacy-rejected-count', params, true);
};
const getCountForLexisNexisRejectedOrders = (params) => {
  return network.get('order/lexis-nexis-rejected-count', params, true);
};

const updateOrderGPAddress = (payload) => {
  return network.post(`order/order-modify-gp-address`, payload);
};

const updateHeightWeight = (payload) => {
  return network.post(`order/updateOrderItems`, payload);
};
const getAllMeetings = () => {
  return network.get('meet/getAllMeet?oldMeet=false', {}, true);
};

const getAllOldMeetings = () => {
  return network.get('meet/getAllMeet?oldMeet=true', {}, true);
};

const getMeetingSlots = (date) => {
  return network.get(`meet/getAvailableMeetSlot?date=${date}`, {}, true);
};

const getNextAvailableMeetingSlots = (date) => {
  return network.get(
    `meet/getAvailableMeetSlot?date=${date}&nextAvailableSlot=true`,
    {},
    true,
  );
};

const scheduleMeet = (payload) => {
  return network.post('meet/addMeet', payload, true);
};

const assignClinicianMeet = (payload, id) => {
  return network.put(`meet/${id}`, payload, true);
};
const getMeetOrders = () => {
  return network.get(`order/getAllMeetOrders`, {}, true);
};

const getAllMeetingSlot = () => {
  return network.get(`meet/getAllMeetingSlot`, {}, true);
};

const updateSlotTime = (payload, id) => {
  return network.put(`meet/update/${id}`, payload, true);
};

const updateSubscription = (id, payload) => {
  return network.put('subscription/' + id, payload, true);
};

const getTextMessages = (orderId) => {
  return network.get(`communication/${orderId}?isSMS=true`);
};

const getOrderImages = (orderId) => {
  return network.get(`order/image/${orderId}`, {}, true);
};

const getAllProductOptions = () => {
  return network.get(`product/getSubscriptionProduct`, {}, true);
};

const getVariantOptions = (id) => {
  return network.get(`product/getSubscriptionProduct/${id}`, {}, true);
};

const uploadOrderImage = (payload) => {
  return network.post('order/imageUpload', payload, true);
};

const submitOrderImage = (id, payload) => {
  return network.put(`order/photos/${id}`, payload, true);
};

const updateWeightStatistic = (payload) => {
  return network.post('order/updateOrderItemsAns', payload, true);
};

const getPendingOrderProductList = () => {
  return network.get('order/pendingOrderProductList', {}, true);
};

export default {
  getAllOrders,
  getOrderById,
  getOrdersOnSearch,
  getOrderItemAnswers,
  changeProductsQuantities,
  setInstructionForProductLabel,
  setStatusOfProductApproval,
  getPastOrders,
  removeNotification,
  putOrderStatus,
  getMessages,
  postMessage,
  updateOrder,
  getDeliveryLabel,
  getSubcatPhotos,
  getCountForPharmacyRejectedOrders,
  getCountForLexisNexisRejectedOrders,
  updateDeliveryAddress,
  updateOrderGPAddress,
  getVariants,
  updateVariantInOrder,
  updateHeightWeight,
  getAllMeetings,
  getAllOldMeetings,
  getMeetingSlots,
  scheduleMeet,
  assignClinicianMeet,
  getMeetOrders,
  getAllMeetingSlot,
  updateSlotTime,
  updateSubscription,
  sendTextMessage,
  getTextMessages,
  getOrderImages,
  getAllProductOptions,
  getVariantOptions,
  getNextAvailableMeetingSlots,
  uploadOrderImage,
  submitOrderImage,
  updateWeightStatistic,
  getPendingOrderProductList
};
