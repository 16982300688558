import React, { useState, useEffect } from 'react';
import debounce from 'lodash.debounce';
import Loader from '../common/UI/Loader';
import ReactTable from '../common/UI/ReactTable';
import SearchAndButtons from '../common/UI/Search&Buttons';
import OrderTable from '../components/Orders/orderTable';
import services from '../api/api';
import { isUserAdmin } from '../utils/helpers';
import deleteIcon from '../assets/images/trash.svg';
import previewIcon from '../assets/images/eye.svg';
import formIcon from '../assets/images/form.png';
import editIcon from '../assets/images/pencil.svg';
import noteIcon from '../assets/images/sticky-notes.png';
import writeNoteIcon from '../assets/images/write.png';
import moment from 'moment';
import { toastError, toastSuccess } from '../common/UI/Toasts';
import { CSVLink } from 'react-csv';
import DeleteConfirmationModal from '../common/UI/CustomModal/deleteModal';
import ViewSubscriptionAnswer from '../components/ViewSubscriptionAnswer';
import FollowupQuesForm from '../components/followupQuesForm';
import { Button } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import ReactTextareaAutosize from 'react-textarea-autosize';
import CustomDropdown from '../common/UI/Dropdown';

// import ViewSubscriptionAnswer from '../components/ViewSubscriptionAnswer';

const SubscriptionManagement = () => {
  const navigate = useNavigate();
  const columns = [
    {
      Header: 'Product Name',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <span
            style={{
              color: row.original.cancelled ? 'red' : 'black',
            }}
          >
            {row.original?.product?.name}
          </span>
        );
      },
    },
    {
      Header: 'Variant',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <span
            style={{
              color: row.original.cancelled ? 'red' : 'black',
            }}
          >
            {row.original?.variant?.attributes
              ? Object.keys(row.original?.variant?.attributes).reduce(
                (previous, currentAttr) => {
                  return (
                    previous +
                    `${currentAttr}-${row.original.variant.attributes[currentAttr]} `
                  );
                },
                ``,
              )
              : ''}
          </span>
        );
      },
    },
    {
      Header: 'Requested Variant',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <span
            style={{
              color: row.original.cancelled ? 'red' : 'black',
            }}
          >
            {row.original?.follow_up_ans[1]?.answer}
          </span>
        );
      },
    },
    {
      Header: 'Send Date',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <span
            style={{
              color: row.original.cancelled ? 'red' : 'black',
            }}
          >
            {row.original?.follow_up_ans != [] &&
              row.original?.follow_up_ans[3]?.answer == 'yes' &&
              row.original?.follow_up_ans[3]?.isDateSelection
              ? 'Yes'
              : row.original?.follow_up_ans[3]?.subQuestions[0]?.answer !=
                'no' && row.original?.follow_up_ans[3]?.isDateSelection
                ? moment(
                  row.original?.follow_up_ans[3]?.subQuestions[0]?.answer,
                ).format('DD/MM/yyyy')
                : ''}
            {row.original?.follow_up_ans != [] &&
              row.original?.follow_up_ans[6]?.answer == 'yes' &&
              row.original?.follow_up_ans[6]?.isDateSelection === true
              ? 'Yes'
              : row.original?.follow_up_ans[6]?.subQuestions[0]?.answer != '' &&
                row.original?.follow_up_ans[6]?.isDateSelection === true
                ? moment(
                  row.original?.follow_up_ans[6]?.subQuestions[0]?.answer,
                ).format('DD/MM/yyyy')
                : ''}
          </span>
        );
      },
    },
    {
      Header: 'Customer Name',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <span
            style={{
              color: row.original.cancelled ? 'red' : 'black',
            }}
          >
            {`${row?.original?.user?.first_name} ${row?.original?.user?.last_name}`}
          </span>
        );
      },
    },
    {
      Header: 'Email',
      disableSortBy: true,
      disableFilters: true,
      width: '120px',
      Cell: ({ row }) => {
        return (
          <span
            style={{
              color: row.original.cancelled ? 'red' : 'black',
            }}
          >
            {`${row?.original?.user?.email}`}
          </span>
        );
      },
    },
    {
      Header: 'Phone Number',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <span
            style={{
              color: row.original.cancelled ? 'red' : 'black',
            }}
          >
            {`${row?.original?.user?.phone}`}
          </span>
        );
      },
    },
    {
      Header: 'Subscription Price',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <span
            style={{
              color: row.original.cancelled ? 'red' : 'black',
            }}
          >
            {row.original?.SubscriptionPrice}
          </span>
        );
      },
    },
    {
      Header: 'Start Date',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <span
            style={{
              color: row.original.cancelled ? 'red' : 'black',
            }}
          >
            {moment(row.original.started_date).format('YYYY-MM-DD')}
          </span>
        );
      },
    },
    {
      Header: 'Next Date',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <span>{moment(row.original.next_due_date).format('DD/MM/YYYY')}</span>
        );
      },
    },
    // {
    //   Header: 'End Date',
    //   disableSortBy: true,
    //   disableFilters: true,
    //   Cell: ({ row }) => {
    //     return (
    //       <span
    //         style={{
    //           color: row.original.cancelled ? 'red' : 'black',
    //         }}
    //       >
    //         {row.original.cancelled_date
    //           ? moment(row.original.cancelled_date).format('YYYY-MM-DD')
    //           : '-'}
    //       </span>
    //     );
    //   },
    // },

    {
      Header: 'Dispatched Orders',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        // let ordersCount = 0;
        // row.original.orders.forEach((element) => {
        //   if (element.current_order_status == 'dispatched') ordersCount += 1;
        // });
        return (
          <span
            style={{
              color: row.original.cancelled ? 'red' : 'black',
            }}
          >
            {row.original?.dispatchedordersno}
          </span>
        );
      },
    },
    {
      Header: 'Answered At',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <span
            style={{
              color: row.original.cancelled ? 'red' : 'black',
            }}
          >
            {row.original?.follow_up_ans_updated_at
              ? moment(row.original?.follow_up_ans_updated_at).format(
                'DD/MM/yyyy',
              )
              : '-'}
          </span>
        );
      },
    },

    {
      Header: 'Delivery Service',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <span
            style={{
              color: row.original.cancelled ? 'red' : 'black',
            }}
          >
            {row.original?.lastorder[0]?.delivery?.title}
          </span>
        );
      },
    },
    {
      Header: 'Action',
      disableSortBy: true,
      disableFilters: true,
      minWidth: '350px',
      Cell: ({ row }) => {
        return (
          <div className="d-flex align-items-center">
            <span
              className="me-2"
              onClick={() => {
                setFollowupForm(row.original);
              }}
            >
              <img src={formIcon} alt="form" width="24" height="24" />
            </span>
            <span
              className="me-2"
              onClick={() => {
                setSubNotes(row.original);
              }}
            >
              <img
                src={
                  row?.original?.notes?.length > 0 ? noteIcon : writeNoteIcon
                }
                alt="eye"
                width="24"
                height="24"
              />
            </span>
            {row.original.follow_up_ans.length != 0 ? (
              <span
                className="me-2"
                onClick={() => {
                  setFollowUpAnswer(row.original.follow_up_ans);
                }}
              >
                <img src={previewIcon} alt="eye" width="24" height="24" />
              </span>
            ) : (
              ''
            )}
            <span
              className="me-2"
              onClick={() => {
                setEditNextDate(row.original);
              }}
            >
              <img src={editIcon} alt="eye" width="24" height="24" />
            </span>
            <span
              className="me-2"
              onClick={() => {
                setIsModalOpen(true);
                setCancelThisSubscription(row.original._id);
              }}
            >
              <img src={deleteIcon} alt="trash" width="24" height="24" />
            </span>
            <Button
              onClick={() => {
                sendFollowupEmail(row.original._id);
              }}
              className="p-2 email_send_btn"
            >
              Send Followup
            </Button>
            <Button
              className="btn btn-primary px-2 py-1 mx-1 email_send_btn"
              onClick={() => handlePauseSubscription(row)}
            >
              Pause
            </Button>
            <Button
              className="btn btn-primary px-2 py-1 email_send_btn"
              onClick={() => handleClearFollowup(row)}
            >
              Clear Followup
            </Button>
          </div>
        );
      },
    },
  ];

  const [data, setData] = useState([]);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [seeAll, setSeeAll] = useState(false);
  const [sendOrders, setSendOrders] = useState(false);
  const [sendDateOrders, setSendDateOrders] = useState(false);
  const [loading, setLoading] = useState(false);
  const userId = localStorage.getItem('userId');
  const [csvData, setCsvData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cancelThisSubscription, setCancelThisSubscription] = useState();
  const [searchVal, setSearchVal] = useState('');
  const [followUpAnswer, setFollowUpAnswer] = useState(false);
  const [editNextDate, setEditNextDate] = useState();
  const [subNotes, setSubNotes] = useState();
  const [addSubNote, setAddSubNote] = useState();
  const [prevSubNoteId, setPrevSubNoteId] = useState();
  const [prevSubNoteData, setPrevSubNoteData] = useState();
  const [nextDueDate, setNextDueDate] = useState();
  const [productsOptions, setProductsOptions] = useState([]);
  const [productSelected, setProductSelected] = useState();
  const [variantOptions, setVariantOptions] = useState([]);
  const [selectedVariant, setSelectedVariant] = useState();
  const [followupForm, setFollowupForm] = useState();
  const [disableButtons, setDisableButtons] = useState(false);
  const [newSubPrice, setNewSubPrice] = useState();

  useEffect(() => {
    setLoading(true);
    getOrders(currentPageNo);
    services
      .getAllProductOptions()
      .then((res) => {
        let options = res.data.data?.map((el) => {
          return {
            label: el?.name,
            value: el?.name,
            productId: el?._id,
          };
        });
        setProductsOptions(options);
      })
      .catch((err) => {
        console.log('err', err);
      });
  }, [currentPageNo, seeAll, searchVal, sendOrders, sendDateOrders]);

  const getProductVariant = (option) => {
    setProductSelected(option.productId);
    services
      .getVariantOptions(option.productId)
      .then((res) => {
        let options = [];
        options = res?.data?.data?.map((el) => {
          let label = Object.keys(el?.attributes).reduce((prev, curr) => {
            return prev + ` ${curr}(${el?.attributes[curr]})`;
          }, '');
          return {
            label,
            value: el?._id,
          };
        });
        setVariantOptions(options);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const handlePauseSubscription = (rowData) => {
    services
      .pauseSubsctiption(rowData?.original?._id)
      .then((res) => {
        toastSuccess('Subscription unpaused successfully!!');
        navigate(0);
      })
      .catch((err) => {
        console.log('Error: ', err);
      });
  };

  const handleClearFollowup = (rowData) => {
    services
      .clearFollowup(rowData?.original?._id)
      .then((res) => {
        toastSuccess('Followup cleared successfully!!');
        navigate(0);
      })
      .catch((err) => {
        console.log('Error: ', err);
      });
  };

  const nextHandle = () => {
    setCurrentPageNo((prev) => prev + 1);
  };

  const prevHandle = () => {
    setCurrentPageNo((prev) => prev - 1);
  };

  const getOrders = (pageNo, value = null) => {
    let params = seeAll
      ? { showall: true }
      : {
        perPage: 20,
        page: pageNo,
      };
    params = {
      ...params,
      cancelled: false,
    };
    if (value || (value === null && searchVal))
      params = {
        ...params,
        search: value === null && searchVal ? searchVal : value,
      };
    params = sendOrders
      ? { isForYes: true, cancelled: false, showall: true }
      : { ...params };

    params = sendDateOrders
      ? { isForNo: true, cancelled: false, showall: true }
      : { ...params };
    services
      .getAllSubscriptions(params)
      .then((res) => {
        setData(res?.data?.data?.subscriptions?.docs);
        loadCSVdata(res?.data?.data?.subscriptions?.docs);
        setTotalPage(res?.data?.data?.subscriptions?.totalPages);
        setLoading(false);
      })
      .catch((err) => {
        console.log('error', err);
        setLoading(false);
      });
  };

  const handleOrderSearch = (value) => {
    setSearchVal(value);
  };

  const onSearchDebounce = debounce(handleOrderSearch, 1000);

  const seeAllHandle = () => {
    setSeeAll((prev) => !prev);
  };

  const sendOrdersHandle = () => {
    setSendOrders((prev) => !prev);
  };

  const sendDateOrdersHandle = () => {
    setSendDateOrders((prev) => !prev);
  };

  const sendFollowupEmail = (id) => {
    services
      .sendFollowupEmail(id)
      .then((res) => {
        toastSuccess('Follow up email sent Successfully!!');
        setLoading(false);
      })
      .catch((err) => {
        console.log('Error: ', err);
        setLoading(false);
      });
  };

  const handleCancelSubWithEmail = () => {
    services
      .cancelSubscription(cancelThisSubscription, { cancelWithEmail: true })
      .then((res) => {
        toastSuccess('Subscription Cancelled Successfully!!');
        setCancelThisSubscription(null);
        setIsModalOpen(false);
        getOrders();
      })
      .catch((err) => {
        console.log('Error: ', err);
        setIsModalOpen(false);
        setCancelThisSubscription(null);
      });
  };

  const handleCancelSubWithoutEmail = () => {
    services
      .cancelSubscription(cancelThisSubscription, { cancelWithEmail: false })
      .then((res) => {
        toastSuccess('Subscription Cancelled Successfully!!');
        setCancelThisSubscription(null);
        setIsModalOpen(false);
        getOrders();
      })
      .catch((err) => {
        console.log('Error: ', err);
        setIsModalOpen(false);
        setCancelThisSubscription(null);
      });
  };

  const updateNextDueDate = (id) => {
    let payload = {};
    if (selectedVariant && nextDueDate) {
      payload = {
        product_id: productSelected,
        variant_id: selectedVariant.value,
        next_due_date: nextDueDate,
      };
    } else if (selectedVariant) {
      payload = {
        product_id: productSelected,
        variant_id: selectedVariant.value,
      };
    } else {
      payload = {
        next_due_date: nextDueDate,
      };
    }

    if (newSubPrice) {
      payload = { ...payload, amount: newSubPrice };
    }

    services
      .updateSubscription(id, payload)
      .then((res) => {
        toastSuccess('Subscription update Successfully!!');
        navigate(0);
      })
      .catch((err) => {
        console.log('Error: ', err);
      });
  };

  const createOrder = (id) => {
    setDisableButtons(true);
    let payload = {};
    if (selectedVariant && nextDueDate) {
      payload = {
        product_id: productSelected,
        variant_id: selectedVariant.value,
        next_due_date: nextDueDate,
      };
    } else if (selectedVariant) {
      payload = {
        product_id: productSelected,
        variant_id: selectedVariant.value,
      };
    } else if (nextDueDate) {
      payload = {
        next_due_date: nextDueDate,
      };
    } else {
      payload = {};
    }

    let changePricePayload = {};

    if (newSubPrice) {
      changePricePayload = { amount: newSubPrice };
      payload = { ...payload, amount: newSubPrice };
    }

    if (!selectedVariant && !nextDueDate) {
      services
        .createOrder(id, changePricePayload)
        .then((res) => {
          toastSuccess('Order Created Successfully!!');
          navigate(0);
        })
        .catch((err) => {
          setDisableButtons(false);
          console.log('Error: ', err);
        });
    } else {
      services
        .updateSubscription(id, payload)
        .then((res) => {
          services.createOrder(id, changePricePayload).then((res) => {
            toastSuccess('Order Created Successfully!!');
            navigate(0);
          });
        })
        .catch((err) => {
          console.log('Error: ', err);
        });
    }
  };

  const CSVheaders = [
    { label: 'Product Name', key: 'product_name' },
    { label: 'Customer Name', key: 'customer_name' },
    { label: 'Email', key: 'email' },
    { label: 'Phone Number', key: 'phone' },
    { label: 'Subscription Price', key: 'sub_price' },
    { label: 'Start Date', key: 'start_date' },
    { label: 'Answered At', key: 'answered_at' },
    { label: 'Dispatched Orders Count', key: 'dispatched_orders_count' },
    { label: 'Delivery Service', key: 'delivery_service' },
    { label: 'Status', key: 'status' },
    { label: 'Next Due Date', key: 'next_due_date' },
    { label: 'Variant', key: 'variant' },
  ];

  const loadCSVdata = (orderdata) => {
    const modifyorderdata = orderdata?.length
      ? orderdata?.map((el) => {
        // let ordersCount = 0;
        // el.orders.forEach((element) => {
        //   if (element.current_order_status == 'dispatched') ordersCount += 1;
        // });
        // let variantAttributes =
        //   el?.orders[el?.orders.length - 1]?.order_items?.variants.attributes;
        return {
          product_name: el?.product?.name,
          customer_name: `${el?.user?.first_name} ${el?.user?.last_name}`,
          email: `${el?.user?.email}`,
          phone: `${el?.user?.phone}`,
          sub_price: el?.SubscriptionPrice, //el?.orders[0]?.total
          start_date: moment(el?.started_date).format('YYYY-MM-DD'),
          answered_at: el?.follow_up_ans_updated_at
            ? moment(el?.follow_up_ans_updated_at).format('YYYY-MM-DD')
            : '-',
          dispatched_orders_count: el.dispatchedordersno,
          delivery_service: el?.lastorder[0]?.delivery?.title, //el?.orders[0]?.delivery?.title
          status: 'Active',
          next_due_date: `${el?.next_due_date.substring(0, 10)}`,
          variant: JSON.stringify(el?.last_order_variant[0]?.attributes),
        };
      })
      : [];
    setCsvData(modifyorderdata);
  };

  const changeNote = (e) => {
    setAddSubNote(e.target.value);
  };

  const changePrevNote = (e) => {
    setPrevSubNoteData(e.target.value);
  };

  const setPrevSubData = (el) => {
    setPrevSubNoteId(el.id);
    setPrevSubNoteData(el.data);
  };

  const submitNote = () => {
    if (addSubNote) {
      services
        .addSubscriptionNote(subNotes._id, { note: addSubNote })
        .then((res) => {
          toastSuccess('Subscription update Successfully!!');
          navigate(0);
        })
        .catch((err) => {
          console.log('Error: ', err);
        });
    } else {
      toastError('Note is empty!!');
    }
  };

  const updateSubscriptionNote = (note, id) => {
    if (note) {
      services
        .updateSubscriptionNote(subNotes._id, { note: note, id: id })
        .then((res) => {
          navigate(0);
          toastSuccess('Subscription update Successfully!!');
        })
        .catch((err) => {
          console.log('Error: ', err);
        });
    } else {
      toastError('Note is empty!!');
    }
  };

  const deleteNote = (id) => {
    services
      .deleteSubscriptionNote(subNotes._id, id)
      .then((res) => {
        navigate(0);
        toastSuccess('Subscription update Successfully!!');
      })
      .catch((err) => {
        console.log('Error: ', err);
      });
  };

  return (
    <>
      {followUpAnswer && (
        <>
          <div className="content_wrapper all_products_main">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="box_main">
                    <Button
                      type="button"
                      variant="sky"
                      className="my-2 me-2"
                      onClick={() => setFollowUpAnswer(null)}
                    >
                      Back
                    </Button>
                    <div
                      className="header_top"
                      style={{ justifyContent: 'space-between' }}
                    >
                      <h3>Follow Up Answers</h3>
                    </div>
                    <ViewSubscriptionAnswer followUpAnswer={followUpAnswer} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {editNextDate && (
        <>
          <div className="content_wrapper all_products_main">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="box_main">
                    <Button
                      type="button"
                      variant="sky"
                      className="my-2 me-2"
                      onClick={() => setEditNextDate(null)}
                    >
                      Back
                    </Button>
                    <div
                      className="header_top"
                      style={{ justifyContent: 'space-between' }}
                    >
                      <h3>Edit Subscription</h3>
                    </div>
                    <div className="col-xl-4 col-md-6 col-lg-6 form-group mt-3">
                      <h6 className="available-text">
                        Next Due Date:{' '}
                        {editNextDate.next_due_date?.substring(0, 10)}
                      </h6>
                      <h6 className="available-text">Select Date</h6>
                      <ReactDatePicker
                        onChange={(value) => {
                          setNextDueDate(value);
                        }}
                        selected={nextDueDate}
                        style={{ zIndex: 99 }}
                        className="form-control form-input px-4"
                        dateFormat="dd/MM/yyyy"
                      />
                      <h6 className="available-text">Select Medication</h6>
                      <CustomDropdown
                        height="44px"
                        className="mx-2"
                        onChange={(value) => {
                          getProductVariant(value);
                        }}
                        border={'1 px solid #9aa5ad'}
                        options={productsOptions}
                      />
                      {productSelected ? (
                        <>
                        <div
                          className="d-flex flex-column"
                          style={{
                            width: '100%',
                          }}
                        >
                          <p className="form-label">Select Variant</p>

                          <CustomDropdown
                            height="44px"
                            className="mx-2"
                            onChange={(value) => {
                              setSelectedVariant(value);
                            }}
                            border={'1 px solid #9aa5ad'}
                            options={variantOptions}
                          />
                        </div>
                        </>
                      ) : null}
                      <input
                        placeholder="Amount"
                        className="form-control me-2  border-1 shadow-none my-2"
                        value={newSubPrice}
                        onChange={(e) => setNewSubPrice(e.target.value)}
                      />
                      <Button
                        className="slot-submit"
                        onClick={() => {
                          updateNextDueDate(editNextDate._id);
                        }}
                        disabled={disableButtons ? 'disabled' : ''}
                      >
                        {disableButtons ? 'Processing..' : 'Submit'}
                      </Button>
                      {!editNextDate?.email_reply_pending && (
                        <Button
                          className="slot-submit"
                          onClick={() => {
                            createOrder(editNextDate._id);
                          }}
                          disabled={disableButtons ? 'disabled' : ''}
                        >
                          {disableButtons ? 'Processing..' : 'Create New Order'}
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {subNotes && (
        <>
          <div className="content_wrapper all_products_main">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="box_main">
                    <Button
                      type="button"
                      variant="sky"
                      className="my-2 me-2"
                      onClick={() => setSubNotes(null)}
                    >
                      Back
                    </Button>
                    <div
                      className="header_top"
                      style={{ justifyContent: 'space-between' }}
                    >
                      <h3>Subscription Notes</h3>
                    </div>

                    {subNotes?.notes?.map((el) => {
                      return (
                        <>
                          <ReactTextareaAutosize
                            onChange={changePrevNote}
                            onClick={() => setPrevSubData(el)}
                            value={
                              prevSubNoteId == el.id ? prevSubNoteData : el.note
                            }
                            minRows={3}
                            placeholder="Add Note"
                            className=" col-md-8 form-group form-input p-2"
                            key={el.id}
                          />
                          <Button
                            className="slot-submit"
                            onClick={() => {
                              updateSubscriptionNote(
                                prevSubNoteData,
                                prevSubNoteId,
                              );
                            }}
                          >
                            Update
                          </Button>
                          <Button
                            className="slot-submit"
                            onClick={() => {
                              deleteNote(el.id);
                            }}
                          >
                            Delete
                          </Button>
                        </>
                      );
                    })}
                    <div
                      className="header_top"
                      style={{ justifyContent: 'space-between' }}
                    >
                      <h3>Add New Notes</h3>
                    </div>
                    <ReactTextareaAutosize
                      onChange={changeNote}
                      value={addSubNote}
                      minRows={3}
                      placeholder="Add Note"
                      className=" col-md-8 form-group form-input p-2"
                    />
                    <div className="col-xl-4 col-md-6 col-lg-6 form-group mt-3">
                      <Button
                        className="slot-submit"
                        onClick={() => {
                          submitNote();
                        }}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {followupForm && (
        <>
          <div className="content_wrapper all_products_main">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="box_main">
                    <Button
                      type="button"
                      variant="sky"
                      className="my-2 me-2"
                      onClick={() => setFollowupForm(null)}
                    >
                      Back
                    </Button>
                    <div
                      className="header_top"
                      style={{ justifyContent: 'space-between' }}
                    >
                      <h3>Follow Up Form</h3>
                    </div>
                    <FollowupQuesForm
                      productId={followupForm.product_id}
                      subscriptionId={followupForm._id}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {!followUpAnswer && !editNextDate && !subNotes && !followupForm && (
        <div className="content_wrapper all_products_main">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="box_main">
                  <div
                    className="header_top"
                    style={{ justifyContent: 'space-between' }}
                  >
                    <h3>Order Management</h3>
                  </div>
                  <div className="middle_main">
                    <SearchAndButtons
                      onSearchChange={onSearchDebounce}
                      searchPlaceholder={'Search by product and user name...'}
                      buttonName={
                        isUserAdmin() ? (
                          <CSVLink
                            data={csvData}
                            filename={`orders_${moment().format(
                              'YYYY_MM_DD_HH_mm_ss',
                            )}.csv`}
                            target="_blank"
                            headers={CSVheaders}
                            style={{ color: '#fff' }}
                          >
                            Export Orders
                          </CSVLink>
                        ) : null
                      }
                      seeAllHandle={seeAllHandle}
                      loading={loading}
                      sendOrdersHandle={sendOrdersHandle}
                      sendDateOrdersHandle={sendDateOrdersHandle}
                      sendOrders={sendOrders}
                      sendDateOrders={sendDateOrders}
                      sendOrdersCheckbox={true}
                    />

                    {loading ? (
                      <Loader loading={loading} />
                    ) : (
                      <ReactTable
                        title="Subscription Orders"
                        tableColumns={columns}
                        tableData={data}
                        seeAll={seeAll}
                        nextHandle={nextHandle}
                        prevHandle={prevHandle}
                        totalPage={totalPage}
                        pageNo={currentPageNo}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <DeleteConfirmationModal
        handleToggle={() => setIsModalOpen(!isModalOpen)}
        show={isModalOpen}
        size="lg"
        handleDelete={handleCancelSubWithEmail}
        handleCancelSubWithoutEmail={handleCancelSubWithoutEmail}
        wording={'Are you sure you want to cancel this subscription?'}
        title={'Cancel Subscription'}
        buttonName={'Cancel'}
      />
    </>
  );
};

export default SubscriptionManagement;
